import React from 'react'
import TagDescriptionWidget from "./widgets/TagDescriptionWidget.js";
import TagServicesWidget from "./widgets/TagServicesWidget";

const BlogSidebar = ({tag}) => {
    const {services} = tag;
    return (
        <div className="blog-tags-title widget-area">
            <TagDescriptionWidget tag={tag}/>

          {/*{services && <TagServicesWidget tag={tag}/>}*/}
        </div>

    )
}

export default BlogSidebar