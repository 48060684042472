import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TagDescriptionWidget = ({tag}) => {

    const {name, image, description} = tag;

    return (
        <div className="widget widget_categories">
            <h3 className="widget-title blog-tag-title">Blogartikel zu {name}</h3>
            {/*<GatsbyImage alt={name} image={getImage(image)}/>*/}

            {/*<p style={{marginTop: "20px", fontSize: "smaller"}}>*/}
            {/*    {description}*/}
            {/*</p>*/}

        </div>
    );
};

export default TagDescriptionWidget;
