import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlogRoll from '../components/BlogContent/BlogRoll'
import MainBanner from "../components/Common/PageBanner.js";
import {graphql} from "gatsby";
import TagsSidebar from "../components/BlogContent/TagsSidebar";
import {Helmet} from "react-helmet";


const PaginatedTagBlogRoll = ({data, pageContext}) => {
    const {edges: posts} = data.allMarkdownRemark;
    const {name} = data.tagsJson;
    const siteUrl = data.site.siteMetadata.siteUrl;

    const ogImage = `${siteUrl}/images/og/blog-${name}-og.png`;

    const desc = `Der Teilbereich des LOGENTIS Blogs gibt Ihnen eine Übersicht aller Artikel über ${name}}. Regelmässig neue Tipps, Tricks und Neuigkeiten erwarten Sie.`;
    const title = `LOGENTIS Blog | Fokus: ${name}`;
    return (
        <Layout>
            <Helmet>
                <title>{title}</title>
                <meta name={"description"} content={desc}/>
                <meta name={"image"} content={ogImage}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={desc}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta property={"og:description"} content={desc}/>
                <meta property={"og:image"} content={ogImage}/>
                <meta property={"og:title"} content={title}/>
            </Helmet>
            <Navbar/>
            <MainBanner pageTitle={name+" Blog"}
                        homePageUrl={"/"}
                        homePageText={"Home"}
                        activePageText={name+" Blog"}
                        withCallToAction={false}/>

            <section className="blog-details-area pb-100">
                <div className="container">
                  <div className="row">
                      <TagsSidebar tag={data.tagsJson} />
                    <BlogRoll cardGrid={"col-lg-6 col-md-6"}
                              posts={posts} numPages={pageContext.numPages}
                              currentPage={pageContext.currentPage} />
                  </div>
                </div>
            </section>
          <Footer />
        </Layout>
    );
};

export default PaginatedTagBlogRoll

export const pageQuery = graphql`
  query PaginatedTagPosts($skip: Int!, $limit: Int!, $tag: String!) {
     site {
        siteMetadata {
            siteUrl
        }
     }
     tagsJson(name: {eq: $tag}) {
        name 
        image {
            childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
            }
        }
        description
     }
        
     allMarkdownRemark(
      filter: {isFuture: {eq: false}, frontmatter: {tags: {eq: $tag}, disabled: {eq: false}}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip) 
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 250)
              fields {
                slug
              }
              frontmatter {
                kurznews
                date(formatString: "DD.MM.YYYY")
                title
                description
                tags
                category
                preview
                moreinfolink
                moreinfotext
                featuredimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                authorFull {
                    email
                    name
                    shortbio
                    title
                    authorimage {
                        childImageSharp {
                            gatsbyImageData(width: 50)
                        }
                    }
                }
                socialmediaimage {
                  childImageSharp {
                    gatsbyImageData
                    resize(width: 1200) {
                      src
                    }
                  }
                }
              }
            }
        }
     }
  }
`
